html,
body {
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'GWENT';
}
.body-container {
  top: 0;
  position: absolute;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  background: url("./_resources/backdrop.jpg") no-repeat center center fixed;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background-size: cover;
}
.inner-container {
  max-height: 100%;
  min-width: 100%;
  padding-bottom: 6rem;
  margin-top: 5rem;
}
.prepend {
  min-width: 65px;
}
.d-cursor {
  cursor: not-allowed;
}
.login-btn {
  width: 100%;
}
.msg-green {
  text-align: center;
  color: green;
  font-weight: 600;
}
.msg-red {
  text-align: center;
  color: #da0a0a;
  font-weight: 600;
}
.modal-body .input-group .prepend {
  width: 4rem;
}
.store-item-paypal {
  display: flex;
  flex-flow: row nowrap;
}
.store-item-paypal .item-img {
  margin-left: 20%;
  width: 96px;
  height: 96px;
}
.store-item-paypal .item-img-wide {
  margin-left: 20%;
  height: 96px;
  border-radius: 20%;
  transition: 400ms ease-out;
  background: linear-gradient(to left, #292929 50%, #800000 50%);
  background-position: right;
  background-size: 200%;
}
.store-item-paypal .item-img-wide:hover {
  background-position: left;
}
.store-item-paypal .item-img-wide2 {
  margin-left: 20%;
  height: 96px;
  border-radius: 20%;
  transition: 1500ms ease-in-out;
  filter: hue-rotate(0deg);
  background-size: 200%;
}
.store-item-paypal .item-img-wide2:hover {
  filter: hue-rotate(360deg);
}
.store-item-paypal .qty {
  vertical-align: middle;
  font-size: medium;
  font-weight: 600;
}
.store-item-paypal .item-name {
  color: #da0a0a;
  font-weight: 600;
}
.store-item-paypal .desc {
  color: #1cc6e4;
}
.store-item-paypal .flash {
  color: #f1b23c;
  animation: rainbow 0.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
@keyframes rainbow {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}
.no-select {
  user-select: none;
  /* default */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  -khtml-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none;
  /* Disable Android and iOS callouts*/
}
.login-spinner {
  width: 12rem;
  height: 12rem;
  display: absolute;
  margin-left: 46%;
  margin-top: 10%;
  padding: 0;
}
@media only screen and (max-width: 629px) {
  .login-spinner {
    margin-left: 20%;
  }
}
@font-face {
  font-family: face;
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url('./_resources/GWENT.ttf') format('ttf');
}
.modal {
  color: #2353d6;
}
.modal .prepend {
  width: 185px;
}
.modal input[type=number] {
  max-width: 120px;
}
.modal .db-modal.modal-dialog {
  width: 840px;
  max-width: unset;
}
.modal .modal-content {
  background-color: rgba(36, 36, 36, 0.795);
}
.modal .drop-result {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}
.modal .drop-result .n {
  min-width: 40%;
}
.modal .drop-result .q,
.modal .drop-result .r {
  min-width: 10%;
}
.modal .drop-result .c,
.modal .drop-result .i {
  min-width: 20%;
}
.modal .mdr-dark {
  background-color: rgba(0, 0, 0, 0.6);
  color: #f1b23c;
}
.modal .mdr-light {
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-backdrop.show {
  opacity: 0.9;
}
.header {
  min-height: 200px;
  z-index: 1;
  max-width: 100%;
  width: 100%;
}
@media only screen and (max-width: 629px) {
  .header {
    max-width: 100vw;
  }
}
.header .header-back {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
  height: 180px;
}
@media only screen and (max-width: 629px) {
  .header .header-back {
    height: 165px;
  }
}
.header .header-back img {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  max-height: 270px;
  justify-self: center;
  border: none;
}
@media only screen and (max-width: 1024px) {
  .header .header-back img {
    margin-top: 2rem;
    max-width: 310px;
  }
}
@media only screen and (max-width: 629px) {
  .header .header-back img {
    margin-top: 3rem;
    max-height: 180px;
    max-width: 270px;
    margin-left: 2%;
    width: 95%;
  }
}
.header .header-back .logo-img {
  filter: brightness(120%);
}
.header .header-back .logo-img:hover {
  filter: brightness(100%);
}
.header .header-back p {
  margin-top: 0.5rem;
  grid-row-start: 1;
  grid-column-start: 4;
  justify-self: center;
  color: #1cc6e4;
}
.header .header-back p .login,
.header .header-back p .create {
  color: #ffffff;
  font-weight: 700;
  text-decoration: underline;
}
.header .header-back p .login:hover,
.header .header-back p .create:hover {
  cursor: pointer;
}
.header .header-back p .logout {
  display: block;
  text-align: right;
  color: #d30909;
  font-weight: 700;
  text-decoration: underline;
  margin-top: 3px;
}
.header .header-back p .logout:hover {
  cursor: pointer;
}
@media only screen and (max-width: 629px) {
  .header .header-back p .logout {
    margin-right: 10px;
  }
}
.header .header-back p .greeting {
  color: #1cc6e4;
  font-weight: 700;
}
.header .header-back p .user {
  color: #007bff;
  cursor: pointer;
}
@media only screen and (max-width: 629px) {
  .header .header-back p {
    grid-column-start: 3;
    grid-column-end: 5;
    justify-self: start;
  }
}
@media only screen and (max-width: 629px) {
  .header .header-back .login-mobile {
    grid-column-start: 3;
    justify-self: end;
  }
}
.home {
  min-height: 100%;
  width: 70%;
  margin: 0 auto;
}
.home .jumbotron {
  color: #f1b23c;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  max-height: 35rem;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 20px rgba(28, 198, 228, 0.6), 0 0 40px rgba(0, 0, 0, 0.6), 0 0 60px rgba(0, 0, 0, 0.6), 0 0 80px rgba(0, 0, 0, 0.6);
  min-height: 50vh;
  padding-top: 1rem;
  padding-bottom: 6rem;
  border-top-left-radius: 16%;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 20%;
}
.home .jumbotron p {
  font-size: 2rem;
  min-width: 100%;
}
.home .jumbotron .welcome {
  text-align: center;
}
.home .jumbotron .align-r {
  text-align: right;
  width: 58%;
}
.home .jumbotron .top-row {
  font-weight: 600;
  font-size: 1.4rem;
  color: #f1b23c;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}
.home .jumbotron .emphasis {
  color: #1cc6e4;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.home .jumbotron .players {
  min-width: fit-content;
  font-size: 1.5rem;
  margin-left: 1rem;
}
@media only screen and (max-width: 629px) {
  .home .jumbotron .players {
    font-size: 1.3rem;
  }
}
.home .jumbotron .p-count {
  color: #007800;
  font-size: 1.5rem;
}
@media only screen and (max-width: 629px) {
  .home .jumbotron .p-count {
    font-size: 1.3rem;
  }
}
.home .jumbotron .news-feed {
  background-color: rgba(0, 0, 0, 0);
  color: #f1b23c;
  font-size: 2rem;
  font-weight: 800;
  height: fit-content;
  padding: 3px;
  vertical-align: middle;
  border-left: 2px outset rgba(28, 198, 228, 0.6);
  border-right: 2px outset rgba(28, 198, 228, 0.6);
  border-top: 1px inset rgba(28, 198, 228, 0.6);
  border-bottom: 1px inset rgba(28, 198, 228, 0.6);
  width: 75%;
  user-select: none;
  /* default */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  -khtml-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none;
  /* Disable Android and iOS callouts*/
}
@media only screen and (max-width: 629px) {
  .home .jumbotron .news-feed {
    width: 100%;
  }
}
@media only screen and (max-width: 629px) {
  .home .jumbotron {
    border: none;
    box-shadow: none;
  }
}
@media only screen and (max-width: 629px) {
  .home {
    width: 100%;
  }
}
.sidebar {
  width: 5rem;
  height: auto;
  position: fixed;
  z-index: 2;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.sidebar a {
  color: #f1b23c;
}
.sidebar .navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.sidebar .nav-item {
  width: 5rem;
  background-color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
  transition: width 300ms ease;
  overflow-x: hidden;
}
.sidebar .nav-item:hover {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
}
.sidebar .nav-item:hover .link-text {
  visibility: visible;
  opacity: 1;
}
.sidebar .nav-item:hover .prev {
  display: none;
}
.sidebar .nav-item:first-child {
  border-top-right-radius: 10px;
  border-top: 1px inset rgba(28, 198, 228, 0.6);
  border-right: 1px inset rgba(28, 198, 228, 0.6);
}
.sidebar .nav-item:last-child {
  border-bottom-right-radius: 10px;
  border-bottom: 1px inset rgba(28, 198, 228, 0.6);
  border-right: 1px inset rgba(28, 198, 228, 0.6);
}
.sidebar .nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  text-decoration: none;
}
.sidebar .nav-link i {
  margin-left: 1.5rem;
  width: 30px;
  height: 30px;
}
.sidebar .link-text {
  font-weight: 700;
  min-width: 8rem;
  visibility: hidden;
  opacity: 0;
  font-size: 1.5rem;
  padding-top: 1rem;
  margin-left: 1rem;
  align-self: center;
  transition: visibility 0ms linear, opacity 500ms ease-in-out;
}
.sidebar .prev {
  margin-top: 0.5rem;
}
.sidebar:hover {
  width: 12rem;
}
.sidebar .fa-toolbox {
  color: #1583a1;
}
@media only screen and (max-width: 629px) {
  .sidebar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin-top: 2rem;
    border-top: 1px inset rgba(28, 198, 228, 0.6);
  }
  .sidebar .navbar-nav {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .sidebar .nav-item {
    background-color: rgba(0, 0, 0, 0);
    width: 5rem;
  }
  .sidebar .nav-item:hover {
    width: 5rem;
  }
  .sidebar .nav-item:hover .link-text {
    visibility: hidden;
  }
  .sidebar .nav-item:hover .prev {
    display: block;
  }
  .sidebar .nav-item:first-child {
    border-top: none;
    border-right: none;
  }
  .sidebar .nav-item:last-child {
    border-bottom: none;
    border-right: none;
  }
  .sidebar .prev {
    margin-left: 33% !important;
  }
  .sidebar:hover {
    width: 100vw;
  }
}
.not-found {
  width: 100%;
  min-height: 100%;
  margin: 3rem auto;
}
.not-found .background-box {
  color: #f1b23c;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 20px rgba(28, 198, 228, 0.6), 0 0 40px rgba(0, 0, 0, 0.6), 0 0 60px rgba(0, 0, 0, 0.6), 0 0 80px rgba(0, 0, 0, 0.6);
  padding: 1rem;
  width: 40%;
  min-width: 400px;
  margin: 0 auto;
  border-top-left-radius: 16%;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 20%;
}
@media only screen and (max-width: 629px) {
  .not-found .background-box {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0.2rem;
  }
}
.not-found .background-box .wrong {
  color: #1cc6e4;
}
.not-found .background-box .redirect {
  color: #1cc6e4;
}
.store {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  columns: calc(100vw / 30);
}
@font-face {
  font-family: 'ROBUST';
  font-style: normal;
  font-weight: 400;
  src: local('ROBUST'), url('https://fonts.cdnfonts.com/s/104613/Robust-8Mo2A.woff') format('woff');
}
@media only screen and (max-width: 629px) {
  .store {
    width: 100%;
    justify-content: center;
  }
}
.store .user-login {
  width: 100%;
  min-height: 80px;
  text-align: center;
  color: #f1b23c;
  font-size: 1.3em;
  font-weight: 600;
}
.store .user-login .background-box {
  background: rgba(0, 0, 0, 0.616) 100%;
  width: 40%;
  min-width: 400px;
  margin: 0 auto;
  border: 2px solid rgba(28, 198, 228, 0.6);
  border-radius: 0.2em;
}
@media only screen and (max-width: 629px) {
  .store .user-login .background-box {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0.2rem;
  }
}
.store .user-login .background-box .welcome {
  min-width: 100%;
  text-align: left;
  margin-left: 10px;
}
.store .user-login .background-box .username {
  font-weight: 700;
  color: #007bff;
}
.store .user-login .background-box .username:hover {
  cursor: pointer;
}
.store .user-login .background-box .logout {
  float: right;
  margin-right: 20px;
}
.store .user-login .background-box .login,
.store .user-login .background-box .create,
.store .user-login .background-box .logout {
  color: #ffffff;
  font-weight: 700;
  text-decoration: underline;
}
.store .user-login .background-box .login:hover,
.store .user-login .background-box .create:hover,
.store .user-login .background-box .logout:hover {
  cursor: pointer;
}
.store .user-login #spin {
  margin-top: 0.5rem;
}
.store .item {
  width: 200px;
  border: none;
  border-radius: 3px;
  transition: 30000ms ease-in;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(28, 198, 228, 0.6);
  margin-top: 1rem;
  text-align: center;
  color: #f1b23c;
  font-weight: 600 !important;
}
.store .item .card {
  border: none;
  background: none;
  padding-top: 15px;
  box-shadow: none;
}
.store .item .card .card-img-top {
  background-color: none;
  width: 96px;
  min-height: 116px;
  margin: 0 auto;
  padding-top: 20px;
}
.store .item .card .title {
  font-family: 'ROBUST', sans-serif;
  background: -webkit-linear-gradient(#ffffff, #888888);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.75rem;
  letter-spacing: 1.5px;
}
.store .item .card img {
  user-select: none;
  max-width: fit-content;
  align-self: center;
  max-height: fit-content;
}
.store .item:hover {
  cursor: pointer;
  background-position: right;
  background-size: cover;
  color: #ffffff;
  animation: pulsate2 3s ease-in-out infinite;
}
@media only screen and (max-width: 629px) {
  .store .item {
    margin-bottom: 1rem;
    margin-top: 0;
    width: 170px;
  }
}
.store .item:not(:hover) {
  animation: pulsate 4s alternate-reverse infinite;
}
@keyframes pulsate {
  0% {
    box-shadow: 0 0 5px rgba(85, 20, 116, 0.87);
  }
  50% {
    box-shadow: 0 0 15px #bd15df;
  }
  100% {
    box-shadow: 0 0 5px rgba(85, 20, 116, 0.87);
  }
}
@keyframes pulsate2 {
  0% {
    box-shadow: 0 0 10px #1f9258;
  }
  33% {
    box-shadow: 0 0 15px #09b36c;
  }
  66% {
    box-shadow: 0 0 20px #0dee85;
  }
  100% {
    box-shadow: 0 0 15px #10b188;
  }
}
.dashboard {
  margin: 0 auto;
  width: 70%;
  color: #f1b23c;
}
.dashboard .dash-header {
  display: inline-flex;
  width: 100%;
  column-gap: 1rem;
}
.dashboard .dash-header #shops-btn,
.dashboard .dash-header #vouchers-btn {
  align-self: flex-end;
  height: 50%;
  line-height: 50%;
}
.dashboard .dash-header #save-prod-btn {
  align-self: flex-end;
  margin-left: auto;
  height: 50%;
  line-height: 50%;
}
.dashboard .drops-form {
  margin-top: 0.5rem;
}
.dashboard .drops-form .dropdown-menu {
  max-height: 250px;
  min-width: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: rgba(28, 198, 228, 0.6) rgba(0, 0, 0, 0.6) !important;
  scrollbar-width: thin;
}
.dashboard .drops-form .name,
.dashboard .drops-form .identifier {
  width: 50%;
}
.dashboard .drops-form .ids {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
}
.dashboard .drops-form .ids .id-input {
  display: inherit;
  margin-left: auto;
}
.dashboard .drops-form .ids .bg-info {
  font-size: 1rem;
  padding-top: 11px;
  width: 85px;
}
.dashboard .drops-form #file-import {
  display: none;
}
.dashboard .drops-form .accordion-body {
  background-color: rgba(0, 0, 0, 0.6);
}
.dashboard .drops-form .accordion-body .divider {
  height: 1rem;
  border-bottom: 2px solid rgba(28, 198, 228, 0.6);
}
.dashboard .drops-form .accordion-body .item-group {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1rem;
  justify-content: space-between;
}
.dashboard .drops-form .accordion-body .item-group .input-group {
  width: unset;
}
.dashboard .drops-form .accordion-body .item-group .chance {
  width: 5rem;
  text-align: center;
  padding-top: 8px;
}
.dashboard .prepend {
  min-width: 120px;
}
.dashboard .check-switch {
  width: 4.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.dashboard .check-label {
  color: orange;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  font-size: 1.5rem;
}
.dashboard input[type=number] {
  max-width: 120px;
}
.dashboard .ico {
  margin-left: 5px;
}
.dashboard .ico:hover {
  cursor: pointer;
}
.dashboard .fa-save,
.dashboard .fa-minus-square,
.dashboard .fa-plus-square,
.dashboard .fa-file-upload,
.dashboard .fa-file-download,
.dashboard .fa-dice {
  pointer-events: none;
  margin-left: 0.5rem;
}
.dashboard .fa-arrow-alt-circle-left {
  pointer-events: none;
}
.dashboard #add-reward {
  margin-top: 0.5rem;
}
.vote {
  width: 70%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 20px rgba(28, 198, 228, 0.6), 0 0 40px rgba(0, 0, 0, 0.6), 0 0 60px rgba(0, 0, 0, 0.6), 0 0 80px rgba(0, 0, 0, 0.6);
  min-height: 50vh;
  padding-top: 1rem;
  padding-bottom: 6rem;
  padding-left: 1rem;
  border-top-left-radius: 16%;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 20%;
}
.vote h1 {
  text-align: center;
  color: #f1b23c;
}
.vote h1 .login {
  text-decoration: underline;
}
.vote h1 .login:hover {
  cursor: pointer;
}
.vote p {
  color: #1cc6e4;
}
.vote div {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}
.vote a {
  padding-left: 3%;
}
@media only screen and (max-width: 629px) {
  .vote {
    box-shadow: none;
    border: none;
    background: transparent;
  }
}
